import JwtDecode from 'jwt-decode';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { CoreUIContext } from '../react-components/core-ui/provider';
import { UserCancelledOperationError } from './errors';

/**
 * Returns a value, or what it was before if it was invalid.
 */
export function useMemory<Supertype, Subtype extends Supertype>(
  value: Supertype,
  isValid: (x: Supertype) => x is Subtype = (x): x is any => x != null
): Subtype | undefined {
  const memory = useRef<Subtype | undefined>(isValid(value) ? value : undefined);
  useEffect(() => {
    if (isValid(value)) {
      memory.current = value;
    }
  }, [value, isValid]);
  return isValid(value) ? value : memory.current;
}

/**
 * Calls the onBlur callback when the element assigned to the returned ref is clicked outside of, with the
 * exception of when the openerRef is clicked.
 */
export function useBlur<TRef extends HTMLElement, TOpener extends HTMLElement = HTMLButtonElement>(onBlur: () => void) {
  const ref = useRef<TRef>(null);
  const openerRef = useRef<TOpener>(null);

  const handleClickOutside = (event: UIEvent) => {
    if (ref.current == null || !(event.target instanceof Node)) return;
    const clickIsOutside = !ref.current.contains(event.target);
    const clickIsOnOpener = openerRef.current == null ? false : openerRef.current.contains(event.target);
    if (clickIsOutside && !clickIsOnOpener) {
      onBlur();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return { ref, openerRef };
}

/**
 * Keeps track of the visibility of an element that should disappear when it is clicked away from. Provides an opener
 * ref, which the element that should open the blurrable component. This element is ignored when clicking outside.
 */
export function useBlurrableComponentVisibility<
  TRef extends HTMLElement,
  TOpener extends HTMLElement = HTMLButtonElement
>(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const { ref, openerRef } = useBlur<TRef, TOpener>(() => setIsComponentVisible(false));
  return { ref, openerRef, isComponentVisible, setIsComponentVisible };
}

/**
 * Shows errors (excluding UserCancelledOperationError) from a promise to the user. Make sure any errors
 * caught by this function are human-readable.
 *
 * @example await request(...).catch(useRequestErrorNotifier("An error occurred."));
 */
export function useErrorNotifier() {
  const { showNotification } = useContext(CoreUIContext);
  return (error: Error) => {
    console.error('error caught', error);
    if (!(error instanceof UserCancelledOperationError)) showNotification({ message: error.message, style: 'danger' });
  };
}

/**
 * Like useEffect, but doesn't run on the first render
 */
export function useUpdateEffect(onUpdate: () => void | (() => void), dependencies: any[]) {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      return onUpdate();
    } else {
      mounted.current = true;
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

/**
 * Like useEffect with no dependencies, only running on mount.
 */
export function useFirstRender(effect: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

/**
 * Forces a re-render.
 */
export function useForceUpdate() {
  return useReducer(() => ({}), {})[1] as () => void;
}

/**
 * Provides an API for working with URL pathname and query parameters. URL changes are not detected if they are
 * triggered manually by pushState or replaceState unless, so use the functions provided by this hook to ensure
 * that component updates occur.
 */
export function useUrl() {
  const router = useRouter();
  const url = new URL(window.location.href);
  return {
    pathname: url.pathname,
    /**
     * The last part of the url pathname. For example, if the pathname is '/x/y', the slug is 'y'
     */
    slug: url.pathname.slice(url.pathname.lastIndexOf('/') + 1),
    searchParams: url.searchParams,
    /**
     *
     * @param genericPath The path that uniquely identifies the next.js page
     * @param mutator A function that changes the old URL into a new one and returns it
     * @param mode replace for replaceState, push for pushState
     * @param shallow Activate shallow mode on NextRouter
     */
    mutate(genericPath: string, mutator: (oldUrl: URL) => URL, mode: 'replace' | 'push', shallow: boolean) {
      const newUrl = mutator(new URL(url.href));
      const relativeUrl = newUrl.href.slice(newUrl.origin.length);
      router[mode](genericPath, relativeUrl, { shallow });
    }
  };
}

/**
 * Used to see if a component may be visible, given a delay to account for time it takes to animate out.
 * @param delay Delay in milliseconds
 * @param mountSlightlyBeforeReveal If true, mounted will be set to true slightly before visible is. This
 * can be useful for triggering a CSS transition from an invisible class to a visible one.
 */
export function useDelayedVisibility(delay: number, mountSlightlyBeforeReveal: boolean = false) {
  const [visible, setVisible] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [timeoutId, setTimeoutId] = useState(-1);
  return {
    visible,
    mounted,
    show() {
      setMounted(true);
      if (mountSlightlyBeforeReveal) {
        // 2 frames seems to be enough to allow the element to mount
        setTimeout(() => setVisible(true), (1000 / 60) * 2);
      } else {
        setVisible(true);
      }
      clearTimeout(timeoutId);
    },
    hide() {
      setVisible(false);
      const id = window.setTimeout(() => setMounted(false), delay);
      setTimeoutId(id);
      clearTimeout(timeoutId);
    }
  };
}

export function useJwtPayload<T>(
  token: string
): { payload: T; error?: never } | { payload?: never; error: 'expired' | 'invalid' } {
  const payload = useMemo(() => (typeof token === 'string' ? (JwtDecode(token) as T & { exp: number }) : undefined), [
    token
  ]);
  return payload && new Date(payload.exp * 1000) > new Date()
    ? { payload }
    : payload
    ? { error: 'expired' }
    : { error: 'invalid' };
}
