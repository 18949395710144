function getCookie(name: string) {
  function escape(s: string) {
    // eslint-disable-next-line no-useless-escape
    return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1');
  }
  const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
  return match ? match[1] : undefined;
}

export default getCookie;
