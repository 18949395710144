import NextApp from 'next/app';
import * as Sentry from 'raven-js';
import { UIDReset } from 'react-uid';
import CoreUIProvider from '../react-components/core-ui/provider';
import InstantTouch from '../react-components/instant-touch';
import StripeProvider from '../react-components/payment/stripe-provider';
import UserProvider from '../react-components/user-provider';
import '../styles/main.css';
import '../styles/notifications.css';

class App extends NextApp {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  // static async getInitialProps(appContext) {
  //   // calls page's `getInitialProps` and fills `appProps.pageProps`
  //   const appProps = await App.getInitialProps(appContext);
  //
  //   return { ...appProps }
  // }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      Sentry.config(process.env.SENTRY_CLIENT_DSN).install();
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <UIDReset>
        <StripeProvider>
          <UserProvider>
            <InstantTouch>
              <CoreUIProvider>
                <a href='#maincontent' className='skip-to-content-link'>
                  Skip to content
                </a>
                <Component {...pageProps} />
                <style jsx>{`
                  .skip-to-content-link {
                    @apply absolute p-2 bg-white text-gray-800 shadow rounded-b z-30;
                    top: 0;
                    left: 50%;
                    opacity: 0;
                  }
                  .skip-to-content-link:focus {
                    opacity: 1;
                  }
                `}</style>
              </CoreUIProvider>
            </InstantTouch>
          </UserProvider>
        </StripeProvider>
      </UIDReset>
    );
  }
}

export default App;
