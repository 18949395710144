import { PropsWithChildren, useEffect } from 'react';

/**
 * Invokes a function on the element and all of its parents, until the root is reached.
 */
function forEachParent(element: HTMLElement, callback: (e: HTMLElement) => void) {
  for (let el: HTMLElement | null = element; el != null; el = el.parentElement) {
    callback(el);
  }
}

function onTouchStart(event: TouchEvent) {
  forEachParent(event.target as HTMLElement, element => element.setAttribute('touched', ''));
}

function onTouchEnd(event: TouchEvent) {
  forEachParent(event.target as HTMLElement, element => element.removeAttribute('touched'));
}

const InstantTouch: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    document.body.addEventListener('touchstart', onTouchStart);
    document.body.addEventListener('touchend', onTouchEnd);
    document.body.addEventListener('touchcancel', onTouchEnd);
    return () => {
      document.body.removeEventListener('touchstart', onTouchStart);
      document.body.removeEventListener('touchend', onTouchEnd);
      document.body.removeEventListener('touchcancel', onTouchEnd);
    };
  }, []);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default InstantTouch;
