import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { createContext, PropsWithChildren, useMemo, useRef } from 'react';

export interface StripeContext {
  loadStripe: () => Promise<Stripe>;
}

export const StripeContext = createContext<StripeContext>(undefined as any);

/**
 * Provides the stripe API, loaded only when needed and then cached. Multiple loadStripe() calls will not result
 * in wasted bandwidth; they will return the same Stripe object.
 */
const StripeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const stripePromise = useRef<Promise<Stripe> | null>(null);
  return (
    <StripeContext.Provider
      value={useMemo(
        () => ({
          loadStripe() {
            if (stripePromise.current == null) {
              stripePromise.current = loadStripe(process.env.STRIPE_PUBLIC_API_KEY).then(stripeOrNull =>
                stripeOrNull == null ? Promise.reject(new Error('Stripe failed to load.')) : stripeOrNull
              );
            }
            return stripePromise.current;
          }
        }),
        []
      )}
    >
      {children}
    </StripeContext.Provider>
  );
};

export default StripeProvider;
