import dynamic from 'next/dynamic';
import type { AddNotificationOptions } from 'react-notifications-component';

const libraryPromise = import('react-notifications-component');

// @ts-ignore
const NotificationContainer = dynamic(() => libraryPromise.then(x => x.ReactNotifications), { ssr: false });

export const store = {
  async addNotification(options: AddNotificationOptions) {
    return (await libraryPromise).Store.addNotification(options);
  },
  async removeNotification(id: number) {
    return (await libraryPromise).Store.removeNotification(id);
  }
};

export default NotificationContainer;
