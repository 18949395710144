import jwtDecode from 'jwt-decode';
import { NextRouter, useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { Business, Member } from '../backend/db/all-entities';
import { AdminJwt, AuthenticationTokenPayload, BusinessJwt, MemberJwt } from '../misc-types/jwt-payload';
import getCookie from '../utils/get-cookie';

/**
 * Logs out the user and sends them to the home page.
 */
function logout(router: NextRouter) {
  document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  router.push('/');
}

interface Props {}

type UserContext =
  | {
      jwt?: undefined;
    }
  | {
      jwt?: MemberJwt;
      user?: Member;
      logout: () => void;
    }
  | {
      jwt?: BusinessJwt;
      user?: Business;
      logout: () => void;
    }
  | {
      jwt?: AdminJwt;
      user: undefined;
      logout: () => void;
    };

export const UserContext = React.createContext<UserContext>(undefined as any);

/**
 * Gets the user's JWT payload client-side. Returns undefined server-side
 */
export function getJwt() {
  if (typeof window === 'undefined') return undefined;
  const token = getCookie('token');
  return token ? (jwtDecode(token) as AuthenticationTokenPayload) : undefined;
}

export const UserProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const router = useRouter();
  const context = useMemo(
    () => (typeof window !== 'undefined' ? ({ jwt: getJwt(), logout: () => logout(router) } as UserContext) : {}),
    [router]
  );
  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export default UserProvider;
