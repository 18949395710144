import classNames from 'classnames';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Transition } from 'react-notifications-component';
import { useMemory } from '../../utils/hooks';
import NotificationContainer, { store } from './notification-container';

export interface CoreUIContext {
  showDialog(dialog: React.FC): void;
  showNotification(options: {
    message: string;
    title?: string;
    style?: 'default' | 'warning' | 'danger' | 'success';
  }): Promise<() => void>;
  _closeCurrentDialog(): void;
}

export const CoreUIContext = React.createContext<CoreUIContext>(undefined as any);

function useDialogQueue() {
  const [queue, setQueue] = useState([] as React.FC[]);
  const current = queue[0] as typeof queue[0] | undefined;
  return {
    enqueue<TButton extends string, TState>(item: React.FC) {
      setQueue([...queue, item]);
    },
    dequeue: useCallback(() => {
      setQueue(dialogs => dialogs.slice(1));
    }, []),
    current
  };
}

export const CoreUIProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { enqueue, dequeue, current } = useDialogQueue();
  const VisibleDialog = useMemory(current);
  const context = useMemo(
    (): CoreUIContext => ({
      showDialog(dialog) {
        enqueue(dialog);
      },
      async showNotification({ title = '', message, style = 'default' }) {
        const transition: Transition = {
          duration: 250,
          timingFunction: 'ease'
        };
        const id = await store.addNotification({
          title,
          message,
          type: style,
          container: 'bottom-right',
          insert: 'top',
          animationIn: ['fade-in'],
          animationOut: ['fade-out'],
          dismiss: {
            duration: 4000
          },
          slidingEnter: transition,
          slidingExit: transition,
          touchRevert: transition,
          touchSlidingExit: transition
        });
        return () => store.removeNotification(id);
      },
      _closeCurrentDialog: dequeue
    }),
    [enqueue, dequeue]
  );
  return (
    <CoreUIContext.Provider value={context}>
      <div aria-live='assertive'>
        <NotificationContainer isMobile />
      </div>
      <div
        className={classNames('main-container fixed flex flex-col inset-0 overflow-auto', current && 'under-dialog')}
      >
        {children}
      </div>
      <div
        className={classNames(
          'bg-white fixed inset-0 z-40',
          current && 'opacity-25',
          !current && 'invisible opacity-0'
        )}
        style={{ transitionDuration: '0.2s' }}
      />
      <div className={classNames('dialog', !current && 'invisible opacity-0')}>
        {VisibleDialog && <VisibleDialog />}
      </div>
      <style jsx>{`
        .dialog {
          @apply bg-gray-900 shadow-2xl fixed flex flex-col z-40 rounded;
          transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform-origin: center;
          width: 36rem;
          max-width: calc(100vw - 2rem);
          min-height: 20rem;
          max-height: 90%;
          will-change: opacity, transform;
        }
        .dialog.invisible {
          transform: translate(-50%, calc(-50% + 10px));
        }
        .main-container {
          transition: transform 0.2s ease-in-out;
          will-change: transform;
        }
        .main-container.under-dialog {
          transform: scale(0.97);
        }
      `}</style>
    </CoreUIContext.Provider>
  );
};

export default CoreUIProvider;
